<template>
	<div ref='gallery' tabindex='0'
		@keydown.esc="closeDialog"
		@keydown.left='prevImage'
		@keydown.right='nextImage'
		v-touch:swipe.left='nextImage'
		v-touch:swipe.right='prevImage'
	>
		<transition name="scale">
			<div class="app-gallery" v-if='value'>
				<transition-group tag="div" class='app-gallery__transition' :name="back? 'slideback' : 'slide'">
					<div class='app-gallery__item'
						v-for='(image, i) in images'
						:key='image'
						v-show="i === active"
					>
						<img :src="`${image}`" class='app-gallery__img'>
						<p class='app-gallery__count tablet-hide'>{{active + 1}}/{{images.length}}</p>
					</div>
				</transition-group>
				<p class='app-gallery__count tablet-show'>{{active + 1}}/{{images.length}}</p>
			</div>
		</transition>

		<template v-if='value'>
			<button class="app-gallery__btn prev" @click.stop="prevImage" v-if='active >= 1'>
				<v-icon icon="arrow-prev" size="13"></v-icon>
			</button>
			<button class="app-gallery__btn next" @click.stop="nextImage" v-if='active < images.length - 1'>
				<v-icon icon="arrow-next" size="13"></v-icon>
			</button>
			<button class="app-gallery__close" @click.stop="closeDialog">
				<v-icon icon="cross" size="20"></v-icon>
			</button>
			<div class="backdrop" style='background-color: rgba(0, 0, 0, 0.85)'></div>
		</template>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		data: () => ({
			back: false,
			active: null
		}),
		props: ['value', 'images', "active_slide"],
		created() {
			this.active = this.active_slide;
		},
		methods: {
			prevImage() {
				if(this.active >= 1) {
					this.active = this.active - 1;
					this.back = true;
				}
			},
			nextImage() {
				if(this.active < this.images.length - 1) {
					this.active = this.active + 1;
					this.back = false;
				}
			},
			closeDialog() {
				this.$emit('input', false);
				this.$emit('close-gallery');
			}
		},
		watch: {
			active_slide() {
				this.active = this.active_slide;
				if(this.value) {
					this.$refs.gallery.focus();
				}

				const hideEl = document.querySelector('.app-gallery')
				this.active_slide != null ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
			}
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.app-gallery {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 12;
		overflow: hidden;
		&__content {
			width: 100%;
			height: 100%;
		}
		&__transition {
			width: 100%;
			height: 100%;
			display: grid;
			align-items: center;
		}
		&__item {
			display: grid;
			padding: 80px * .8 0;
			width: 100%;
			max-height: 100%;
			@include minw($grid-breakpoints-lg) {
				padding: 80px 0;
			}
		}
		&__count {
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			margin-top: -30px * .8;
			font-size: 16px;
			line-height: 20px;
			font-weight: 700;
			color: $white;
			text-align: center;
			@include minw($grid-breakpoints-lg) {
				bottom: -30px;
			}
			@include maxw($grid-breakpoints-sm - 1) {
				position: fixed;
				top: auto;
				bottom: 42px;
				margin-top: 0;
			}
		}
		&__img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: calc(100vh - (80px * .8) * 2);
			object-fit: contain;
			margin: auto;
			@include minw($grid-breakpoints-lg) {
				max-height: calc(100vh - 80px * 2);
			}
		}
		&__btn {
			position: fixed;
			z-index: 15;
			color: $white;
			width: 55px * .8;
			height: 55px * .8;
			border: 1px solid $white;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: opacity $transition;
			bottom: 30px;
			@include minw($grid-breakpoints-sm) {
				top: 50%;
				transform: translateY(-50%);
			}
			@include minw($grid-breakpoints-lg) {
				width: 55px;
				height: 55px;
			}
			&:hover {
				opacity: .8;
			}
			&.next {
				right: 20px;
				@include minw($grid-breakpoints-sm) {
					right: 6vw * .8;
				}
				@include minw($grid-breakpoints-lg) {
					right: 6vw;
				}
			}
			&.prev {
				left: 20px;
				@include minw($grid-breakpoints-sm) {
					left: 6vw * .8;
				}
				@include minw($grid-breakpoints-lg) {
					left: 6vw;
				}
			}
		}
		&__close {
			width: 20px;
			height: 20px;
			position: fixed;
			right: 25px;
			top: 25px;
			z-index: 15;
			cursor: pointer;
			color: $white;
			svg path {
				transition: opacity $transition;
			}
			&:hover {
				opacity: .8;
			}
		}
	}
	.scale {
		&-enter-active,
		&-leave-active {
			@media screen {
				overflow: hidden;
			}
			transition: opacity $transition, transform $transition;
		}
		&-enter,
		&-leave-to {
			opacity: 0;
			transform: scale(0);
		}
	}

	.slide {
		&-leave-active,
		&-enter-active {
			position: absolute;
			transition: $transition;
		}
		&-enter {
			transform: translate(100%, 0);
		}
		&-leave-to {
			transform: translate(-100%, 0);
		}
	}

	.slideback {
		&-leave-active,
		&-enter-active {
			position: absolute;
			transition: $transition;
		}
		&-enter {
			transform: translate(-100%, 0);
		}
		&-leave-to {
			transform: translate(100%, 0);
		}
	}
</style>
