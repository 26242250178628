<template>
	<div class='a-hotel-rooms-wrap'>
		<div class="a-hotel-rooms-header">
			<div class='row'>
				<div class='col-auto'>
					<p class='a-hotel-rooms-header-title'>
						<!-- <span>4х</span> -->
						{{offer.roomName}}
					</p>
				</div>
				<!-- <div class='col-auto'>
					<p class='a-hotel-rooms-header-descr error--text'>{{$t("account.result.remains")}} 3 номери!</p>
				</div> -->
			</div>
		</div>

		<div class='a-hotel-rooms-container'>
			<div class='a-hotel-rooms-item'>
				<div class="row align-items-end">
					<div class="col">
						<p class='a-hotel-rooms-item-title'>{{offer.boardName}}</p>
						<!-- <ul class='a-hotel-rooms-item-list'>
							<li class='a-hotel-rooms-item-list-cell'>
								<v-icon icon='wifi' size='13' />
								Безкоштовний Wi-Fi
							</li>
						</ul> -->
					</div>
					<div class="col-auto">
						<!-- <div class='mobile-hide'>
							<v-button xxsmall outline color='green' class='a-hotel-rooms-button-compare' style='border: none'
								@click.stop='$emit("update-compare", "delete")'
								v-if='compare'
							>
								<v-icon icon='delete' size='14' />
								{{$t('account.comparison.remove_from_comparison')}}
							</v-button>
							<v-button xxsmall outline color='green' class='a-hotel-rooms-button-compare' @click.stop='$emit("update-compare", "add")' v-else>{{$t('account.comparison.add_to_compare')}}</v-button>
						</div> -->
					</div>
				</div>
			</div>

			<div class='a-hotel-rooms-footer'>
				<div class='row align-items-end'>
					<div class="col">
						<p class='a-hotel-rooms-button-link' @click='cancellationPolicies = true' v-if='!cancellationPolicies'>{{$t("account.result.terms_of_cancellation")}}</p>
						<template v-else>
							<p class='a-hotel-rooms-text' v-for="(item, i) in offer.cancellationPolicies" :key='i'>{{$t("account.result.after")}} {{$moment(item.from).format('DD.MM.YYYY')}} {{$t("account.result.cancellation_will_be")}} {{$helpers.amount(item.amount, offer.currency)}}</p>
						</template>
					</div>
					<div class="col-auto">
						<div class='a-hotel-rooms-footer-action'>
							<Total
								:isIcon='true'
								:offer="offer"
								:night="$moment(initialItems.searchData.dateTo).diff($moment(initialItems.searchData.dateFrom), 'days')"
							/>
							<div class='a-hotel-rooms-footer-button'>
								<v-button block xxsmall color='green white--text' @click='$emit("open-details")'>{{$t('account.buttons.choose')}}</v-button>
								<!-- <div class='mobile-show' style='margin-top: 10px'>
									<v-button block xxsmall outline color='green' class='a-hotel-rooms-button-compare' style='border: none'
										@click.stop='$emit("update-compare", "delete")'
										v-if='compare'
									>
										<v-icon icon='delete' size='14' />
										{{$t('account.buttons.remove')}}
									</v-button>
									<v-button block xxsmall outline color='green' class='a-hotel-rooms-button-compare' @click.stop='$emit("update-compare", "add")' v-else>
										<v-icon icon='graph' size='12' />
										{{$t('account.comparison.for_comparison')}}
									</v-button>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Total from '@/views/account/result/hotels/total';

	export default {
		data: () => ({
			cancellationPolicies: false
		}),
		props: ['offer', 'initialItems'],
		components: {
			Total
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-hotel-rooms {
		&-wrap {
			background-color: $white;
			box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
			border-radius: 10px;
			padding: 20px 15px 5px;
			margin-bottom: 10px;
			@include minw($grid-breakpoints-xs) {
				padding: 20px 10px 15px 25px;
			}
		}
		&-header {
			.row {
				margin: 0 -10px;
				align-items: baseline;
			}
			.col-auto {
				padding-left: 10px;
				padding-right: 10px;
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
				span {
					color: rgba($cyprus, .8);
				}
			}
			&-descr {
				font-size: 12px;
				font-weight: 400;
			}
		}
		&-container {
			padding: 15px 0;
			@include minw($grid-breakpoints-sm) {
				padding: 20px 25px;
			}
			&:not(:last-child) {
				@include maxw($grid-breakpoints-xs - 1) {
					padding-bottom: 30px;
				}
				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					right: -20px;
					left: -20px;
					height: 1px;
					background-color: rgba($black, .1);
					@include minw($grid-breakpoints-xs) {
						right: 25px;
						left: 25px;
					}
				}
			}
		}
		&-item {
			&-title {
				font-size: 16px;
				font-weight: 400;
				color: rgba($cyprus, .8);
				margin-bottom: 10px;
			}
			&-list {
				&-cell {
					font-size: 13px;
					font-weight: 400;
					color: rgba($black, .5);
					display: flex;
					align-items: center;
					margin-bottom: 5px;
					.app-icon {
						margin-right: 10px;
					}
					&.active {
						color: #00AF85;
					}
				}
			}
		}
		&-button {
			&-link {
				font-size: 13px;
				font-weight: 400;
				color: $green-persian;
				cursor: pointer;
				transition: color $transition;
				&:hover {
					color: $green-dark;
				}
			}
			&-compare {
				height: 40px;
				padding: 0 10px;
			}
		}
		&-text {
			font-size: 13px;
			font-weight: 400;
		}
		&-footer {
			margin-top: 20px;
			@include maxw($grid-breakpoints-sm - 1 ) {
				.row {
					flex-direction: column;
					align-items: inherit;
				}
			}
			&-action {
				margin-top: 15px;
				@include minw($grid-breakpoints-xs) {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				@include minw($grid-breakpoints-sm) {
					margin-top: 0;
				}
			}
			&-button {
				margin-top: 15px;
				@include minw($grid-breakpoints-xs) {
					margin-top: 0;
					width: 125px;
					margin-left: 30px;
				}
			}
		}
	}
</style>
