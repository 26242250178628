<template>
	<MglMap
		:accessToken="accessToken"
		:mapStyle="mapStyle"
		:center="coordinates"
		:zoom="zoom"
	>
		<MglMarker
			:coordinates.sync="coordinates"
		/>

		<MglGeolocateControl position="bottom-right" />
		<MglNavigationControl position="bottom-right" />
	</MglMap>
</template>

<script>
	import 'mapbox-gl/dist/mapbox-gl.css';
	import Mapbox from "mapbox-gl";
	import {
		MglMap,
		MglMarker,
		MglNavigationControl,
		MglGeolocateControl,
	} from "vue-mapbox";

	export default {
		components: {
			MglMap,
			MglMarker,
			MglNavigationControl,
			MglGeolocateControl
		},
		data: () => ({
			accessToken: "pk.eyJ1IjoiYmlsYW4tZGV2ZWxvcGVyIiwiYSI6ImNsamZ4MzIyNTA0cWYzaHA5MnRvMDBobmEifQ.IaLQB22LWM8eAqvElAfUPA",
			mapStyle: "mapbox://styles/mapbox/streets-v11",
			zoom: 15
		}),
		props: {
			coordinates: {
				type: Array,
				default: () => [0, 0]
			}
		},
		created() {
			this.mapbox = Mapbox;
		}
	};
</script>

<style lang="scss">
	.mgl-map-wrapper {
		width: 100%;
		min-height: 100%;
		height: 446px;
		* {
			position: initial;
		}
		.mapboxgl-marker,
		.mapboxgl-ctrl-bottom-left,
		.mapboxgl-ctrl-bottom-right,
		.mapboxgl-ctrl-top-left,
		.mapboxgl-ctrl-top-right {
			position: absolute;
		}
		.mapboxgl-marker svg {
			width: 27px;
			height: 41px;
		}
	}
</style>
