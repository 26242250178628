<template>
	<div>
		<skeleton-hotel v-if='loading' />

		<div class='a-hotel' v-else>
			<div class='a-hotel-sliders'>
				<div class="a-hotel-sliders-wrap" v-if="images.length">
					<div class='a-hotel-sliders-group mobile-hide'>
						<div class='a-hotel-sliders-small' v-for='(item, i) in images.slice(1, 5)' :key='i'>
							<div class='a-hotel-sliders-link' @click='active = true, active_slide = i + 1'>
								<img :src="item">
							</div>
						</div>
					</div>
					<div class='a-hotel-sliders-big'>
						<div class='a-hotel-sliders-link' @click='active = true, active_slide = 0'>
							<img :src="images[0]">
							<div class='a-hotel-sliders-chip'>
								<span>{{images.length}}</span>
								<v-icon icon='photos' size='14' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<trips-gallery
				v-model='active'
				:images='images'
				:active_slide='active_slide'
				@close-gallery='active_slide = null'
			/>
			<div class="app-container a-hotel-container">
				<div class='a-hotel-header'>
					<div class="row">
						<div class="col">
							<TripAdvisor
								class='a-hotel-tripadvisor mobile-show'
								:rating='hotel.rating'
								:countRewards='hotel.countRewards'
								@click.native="scrollFix('#reviews')"
							/>
							<ul class='a-hotel-stars-list'>
								<li class='a-hotel-stars-item' v-for='(item, i) in hotel.categorySimpleCode' :key='i'>
									<v-icon icon='star' size='18' />
								</li>
							</ul>
							<p class='a-hotel-title'>{{hotel.name}}</p>
							<a class='a-text-with-icon app-link' style='font-weight: 400 !important' :href='initialItems.place' target="_blank">
								<v-icon icon='pin' size='13' />
								<span>{{hotel.address}}, {{hotel.postalCode}} {{hotel.countryName}}</span>
							</a>

							<div class='a-hotel-text-container'>
								<p class='a-hotel-text'>{{hotel.description.split('.').filter(el => el != ' ')[0]}}.</p>
								<div v-if='more_text'>
									<p class='a-hotel-text' v-for="(item, i) in hotel.description.split('.').filter(el => el != ' ').slice(1, -1)" :key='i'>{{item}}.</p>
								</div>
								<span class='app-link green--text' @click='more_text = !more_text'>{{more_text ? $t('account.result.more_text_hide') : $t('account.result.more_text_show')}}</span>

								<div class='a-hotel-wrap' v-if='hotel.facilitiesFilter.length'>
									<p class='a-hotel-sub-title'>{{$t('account.result.hotel_facilities')}}</p>
									<div class="a-hotel-comfort">
										<ul class='a-hotel-comfort-list'>
											<li class='a-hotel-comfort-item' v-for='(item, i) in hotel.facilitiesFilter' :key='i'>
												<v-icon icon='check' size='10' />
												{{item}}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="col-auto">
<!--							<TripAdvisor-->
<!--								class='a-hotel-tripadvisor mobile-hide'-->
<!--								:rating='hotel.rating'-->
<!--								:countRewards='hotel.countRewards'-->
<!--								@click.native="scrollFix('#reviews')"-->
<!--								style='cursor: pointer'-->
<!--							/>-->
							<div class="sticky">
								<div class='a-hotel-button-group'>
									<div class='a-hotel-button-group-cell'>
										<v-button block xxsmall outline color='green' class='a-hotel-button-favourite favourite' :class='{"app-btn-active favourite-active": favourite}'
											@click='favourite ? hotelFavoriteUnchecked() : hotelFavoriteChecked()'
											:loading='loading_btn'
										>
											<v-icon icon='favourite' size='14' />
											<span class='desktop-show'>{{favourite ? $t('account.buttons.remove') : $t('account.comparison.to_the_chosen_one')}}</span>
											<span class='desktop-hide'>{{favourite ? $t('account.comparison.remove_from_favorites') : $t('account.comparison.to_the_chosen_one')}}</span>
										</v-button>
									</div>
									<div class='a-hotel-button-group-cell' v-if='$route.query.orderId'>
										<v-button block xxsmall color='green white--text'
											@click.native="scrollFix('#available-rooms')"
										>{{$t('account.result.available_rooms')}}</v-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<form class='a-booking-form-default a-hotel-form'>
					<div class='a-booking-form-row'>
						<div class="a-booking-form-col">
							<a-date-picker
								v-model='form.date'
								@change='changeDate($event)'
								:errors='!form.date && errors.dateFrom'
								:placeholder="`${$t('account.form.placeholder.dateFrom')} — ${$t('account.form.placeholder.dateTo')}`"
								range
								class='mobile-hide'
							/>
							<a-date-picker-mobile
								v-model='form.date'
								@change='changeDate($event)'
								:errors='!form.date && errors.dateFrom'
								:placeholder="`${$t('account.form.placeholder.dateFrom')} — ${$t('account.form.placeholder.dateTo')}`"
								range
								class='mobile-show'
							/>
						</div>
						<div class="a-booking-form-col" :style='form.tourists.length ? "pointer-events: none; opacity: .5": ""'>
							<select-people
								:adults='form.adults'
								:childrenCount='form.childrenCount'
								:children='form.children'
								:placeholder="$t('account.home.hotels.count_tourists_and_rooms')"
								@update-people='$event => form = {...form, ...$event}'
								isRooms
								isSingleRoom
								:errors='!form.rooms.length && errors.rooms'
								:tourists='form.tourists'
							/>
						</div>
						<div class="a-booking-form-col a-booking-form-col-12">
							<a-autocomplete
								:placeholder="$t('account.home.hotels.nationality_tourists')"
								v-model='form.nationality'
								:requestName='getAutocompleteNationality'
								item-value='code'
								return-object
								actionInitial
								append-icon='arrow-down'
							/>
						</div>
					</div>

					<select-tourist
						:initialTourists='form.tourists'
						@tourists='form.tourists = $event'
						@add-tourist='form.travelersIds = $event'
						@remove-tourist='form.travelersIds.splice($event, 1)'
					/>

					<div class='row a-booking-form-action'>
						<div class="col">
							<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
						</div>
						<div class="col-auto">
							<v-button xxsmall color='green white--text' @click='hotelSearch' :loading='loading_btn'>{{$t("account.booking.content.search")}}</v-button>
						</div>
					</div>
				</form>

				<div class='a-hotel-wrap' id='available-rooms' v-if='$route.query.orderId'>
					<div class="row align-items-center mb-4 a-hotel-select-row">
						<div class="col">
							<p class='a-hotel-sub-title mb-0'>{{$t('account.result.available_rooms')}}</p>
						</div>
						<div class="col-auto">
							<div class='a-hotel-select-sorting' v-if='items.length'>
								<span class='app-label'>{{$t("account.sidebar.hotels.type_of_food")}}:</span>
								<a-select
									:options='items_sorting'
									v-model='active_sorting'
									xsmall
								/>
							</div>
						</div>
					</div>
					<div class='a-hotel-rooms'>
						<skeleton-rooms v-if='loading_hotel' />

						<template v-else>
							<trips-preloader
								name='hotels'
								:title="$t('account.preloader.noData.hotels_rooms')"
								v-if='!loading_hotel && initialItems.length === 0 && lastResult'
							/>

							<div class='a-hotel-rooms-box' v-else
								v-for='(groupOffers, i) in items' :key='i'
							>
                                <trips-offer
                                    v-for='(offer, i) in groupOffers' :key='i'
                                    :offer='offer'
                                    :initialItems='initialItems'
                                    @open-details='openDetails(offer)'
                                />
							</div>

						</template>
					</div>
				</div>

				<div class='a-hotel-wrap'>
					<p class='a-hotel-sub-title'>{{$t('account.result.location')}}</p>

					<Mapbox :coordinates='coordinates' />
				</div>

<!--				<div class='a-hotel-wrap' id='reviews'>-->
<!--					<p class='a-hotel-sub-title'>{{$t('account.result.reviews')}}</p>-->
<!--					<iframe-->
<!--						class='a-hotel-iframe-review'-->
<!--						:src="`${iframeReview}&locationId=${$route.params.code}`"-->
<!--					/>-->
<!--				</div>-->
			</div>
		</div>

		<trips-card-details v-model='card_details'
			:offer='offer'
			:form='form'
			:transfers='transfers'
			@open-dialog='openDialog($event)'
			@update-message='userComment = $event'
		/>
		<trips-check-transfer
			:total='offer ? $helpers.amount((active_transfer.price ? active_transfer.price : 0) + offer.amount, offer.currency) : null'
			:nights="initialItems.searchData ? $moment(initialItems.searchData.dateTo).diff($moment(initialItems.searchData.dateFrom), 'days') : null"
			:offer='offer'
			:active_transfer='active_transfer'
			:userComment='userComment'
			v-model='transfers'
			@close-dialog='transfers = false'
		/>
		<!-- <trips-comparison
			:items='items_compare'
			@clear-compare='items_compare = []'
			@router-compare='routerCompare'
		/> -->
	</div>
</template>

<script>
	import SkeletonHotel from '@/views/account/result/hotels/skeleton-hotel';
	import SkeletonRooms from '@/views/account/result/hotels/skeleton-rooms';
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	import TripsOffer from '@/views/account/result/hotels/offer';
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsCardDetails from '@/views/account/details/hotels';
	import TripsCheckTransfer from '@/views/account/offers/transfers';
	// import TripsComparison from '@/views/account/search/components/comparison';
	import TripsGallery from '@/views/account/result/hotels/gallery';
	import TripAdvisor from '@/views/account/result/hotels/tripadvisor';
	import Mapbox from "@/components/Mapbox/index";
	import { hotelSearch, getHotelDetails, getHotelSearch, hotelFavorite, hotelFavoriteChecked, hotelFavoriteUnchecked, getAutocompleteNationality } from '@/services/request';
	import { i18n } from '@/plugins/i18n';
	import _ from 'lodash'

	export default {
		data: () => ({
			more_text: false,
			setTimeout: null,
			open: false,
			storageId: null,
			active: null,
			active_slide: null,
			hotel: {},
			images: [],
			initialItems: [],
			// items_compare: [],
			items_favourite: [],
			form: {
				rooms: [],
				country: null,
				destination: null,
				date: null,
				dateFrom: null,
				dateTo: null,
				night: null,
				travelersIds: [],
				resetCache: false,
				initialHotel: null,
				nationality: null,
				showSimilar: false,
				tourists: []
			},
			loading: false,
			loading_btn: false,
			loading_hotel: false,
			lastResult: false,
			errors: {},
			offer: null,
			card_details: false,
			transfers: false,
			coordinates: [0, 0],
			iframeReview: 'https://www.tripadvisor.com/WidgetEmbed-cdspropertydetail?display=true&partnerId=6CA95E2409844D6183003CEFCDDC2BDE&lang=ru',
			progress: [],
			active_sorting: "all-types",
			active_transfer: {},
			userComment: null
		}),
		computed: {
			items() {
				if(this.active_sorting === "all-types") {
					// return Object.values(this.$_.groupBy(this.initialItems.offers, "roomCode"))
					return [_.sortBy(this.initialItems.offers, "amount")]
				} else {
					return [_.sortBy(this.initialItems.offers.filter(el => el.boardName === this.active_sorting), "amount")]
				}
			},
			items_sorting() {
                return [
					{id: 'all-types', name: i18n.t("account.sidebar.hotels.all_types")},
					...this.initialItems.offers ? this.$_.uniq(this.$_.map(this.initialItems.offers, "boardName")).map(el => ({id: el, name: el})) : []
				]
			},
			favourite() {
				return this.items_favourite.some(el => el.code === this.hotel.code)
			}
		},
		components: {
			SkeletonHotel,
			SkeletonRooms,
			SelectPeople,
			SelectTourist,
			TripsOffer,
			TripsPreloader,
			TripsCardDetails,
			TripsCheckTransfer,
			// TripsComparison,
			TripAdvisor,
			TripsGallery,
			Mapbox
		},
		created() {
			this.getHotelDetails();
			this.hotelFavorite();

			if(this.$route.query.source) {
				this.form = JSON.parse(this.$route.query.source)
				this.hotelSearch("init")
			}
		},
		methods: {
			getAutocompleteNationality,
			getHotelDetails() {
				this.loading = true

				getHotelDetails(this.$route.params.code).then(res => {
					this.hotel = res.data;

					this.form.country = res.data.countryCode;
					this.form.destination = res.data.destinationCode;

                    this.images = this.$_.uniqBy(this.hotel.images)

					this.loading = false;
					document.title = this.hotel.name + ' - ' + process.env.VUE_APP_TITLE;
					this.coordinates = [this.hotel.longitude, this.hotel.latitude];
				})
			},
			hotelSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading_btn = true;
				this.loading_hotel = !this.progress.some(() => true);
				let orderId = this.$route.query.orderId ? { orderId: this.$route.query.orderId } : false;

				hotelSearch({...this.form, ...orderId, hotel: this.$route.params.code }).then(res => {
					if(this.$route.query.storageId !== res.data.storageId) {
						this.$router.replace({
							query: {orderId: res.data.orderId, storageId: res.data.storageId, source: JSON.stringify(this.form), ...(this.$route.query.tripName ? {tripName: this.$route.query.tripName} : {})}
						})
					}
					this.storageId = res.data.storageId;

					this.getHotelSearch();
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
					this.loading_hotel = false;
				}).finally(() => this.loading_btn = false)
			},
			getHotelSearch(init) {
				this.loading_hotel = init ? true : !this.progress.some(() => true);

				getHotelSearch(this.storageId).then(res => {
					if(res.data !== null) {
						this.initialItems = (res.data.offers.length && res.data.offers.find(el => el.id === this.hotel.id)) ? res.data.offers.find(el => el.id === this.hotel.id) : [];

						this.lastResult = res.data.lastResult;
						this.progress = res.data.progress;

						if(!this.lastResult) {
							this.setTimeout = setTimeout(() => this.getHotelSearch(), 1500);
						} else {
							this.loading_hotel = false
						}
						if(this.progress.some(() => true)) {
							setTimeout(() => this.loading_hotel = false, 1500);
						}
					} else {
						this.initialItems = [];
						this.lastResult = true;
						this.progress = [];
					}
				})
			},
			changeDate($event) {
				this.form.date = $event[0] ? $event : null;
				this.form.dateFrom = this.$moment($event[0]).format('YYYY-MM-DD');
				this.form.dateTo = this.$moment($event[1]).format('YYYY-MM-DD');
				this.form.night = this.$moment($event[1]).diff(this.$moment($event[0]), 'days');
			},
			updatePeople($event) {
				this.form = {...this.form, ...$event}
			},
			openDetails(item) {
				this.card_details = true;
				this.offer = item;
			},
			scrollFix(hashbang) {
				const container = document.querySelector(hashbang).offsetTop;
				const header = document.querySelector('.a-header').offsetHeight;
				const sliders = document.querySelector('.a-hotel-sliders').offsetHeight;

				window.scrollTo({
					top: container + header + sliders - 50,
					behavior: 'smooth'
				})
			},
			hotelFavorite() {
				hotelFavorite().then(res => this.items_favourite = res.data);
			},
			hotelFavoriteChecked() {
				this.loading_btn = true;

				hotelFavoriteChecked(this.hotel.code)
					.then(() => this.hotelFavorite())
					.finally(() => this.loading_btn = false)
			},
			hotelFavoriteUnchecked() {
				this.loading_btn = true;

				hotelFavoriteUnchecked(this.hotel.code)
					.then(() => this.hotelFavorite())
					.finally(() => this.loading_btn = false)
			},
			openDialog($event) {
				this.transfers = true;
				this.active_transfer = $event;
			}
		},
		beforeDestroy() {
			clearTimeout(this.setTimeout)
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-hotel {
		padding-bottom: 100px;
		&-sliders {
			overflow: hidden;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 40px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-bottom: 40px;
			}
			@include minw( $grid-breakpoints-xl ) {
				max-width: $grid-breakpoints-xl;
				margin-right: auto;
				margin-left: auto;
			}
			&-wrap {
				display: flex;
			}
			&-group {
				flex: 0 0 auto;
				display: flex;
				flex-wrap: wrap;
				width: 39vw;
				@include minw( $grid-breakpoints-xl ) {
					width: 760px;
				}
			}
			&-link {
				display: flex;
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			&-small {
				display: flex;
				height: calc(28vw / 2);
				flex: 0 0 calc(100% / 2);
				max-width: calc(100% / 2);
				padding-right: 5px;
				padding-bottom: 5px;
				@include minw( $grid-breakpoints-xl ) {
					height: calc(535px / 2);
				}
			}
			&-big {
				height: 400px;
				flex: 1 1 100%;
				padding-bottom: 10px;
				@include minw( $grid-breakpoints-xs ) {
					height: 28vw;
					padding-bottom: 5px;
				}
				@include minw( $grid-breakpoints-xl ) {
					height: 535px;
				}
			}
			&-chip {
				position: absolute;
				bottom: 15px;
				right: 15px;
				color: $white;
				background-color: rgba($black, .5);
				border-radius: 3px;
				font-size: 13px;
				line-height: 1;
				font-weight: 700;
				display: flex;
				align-items: center;
				padding: 7px;
				@include minw( $grid-breakpoints-xs ) {
					bottom: 30px;
					right: 60px;
				}
				.app-icon {
					margin-left: 4px;
				}
			}
		}

		&-container {
			max-width: 864px;
		}
		&-header {
			margin-bottom: 40px * .8;
			@include minw( $grid-breakpoints-md ) {
				margin-bottom: 40px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.col-auto {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
		&-wrap {
			margin-top: 60px * .8;
			@include minw( $grid-breakpoints-md ) {
				margin-top: 60px;
			}
		}

		&-stars {
			&-list {
				display: flex;
				margin-bottom: 5px;
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 20px * .8;
				}
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 20px;
				}
			}
			&-item {
				margin: 0 3px;
				color: rgba($cyprus, .5);
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin: 0 1px;
					.app-icon {
						width: 10px !important;
						height: 10px !important;
					}
				}
			}
		}

		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 5px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 28px * .8;
				margin-bottom: 10px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 28px;
				margin-bottom: 10px;
			}
		}
		&-sub-title {
			font-size: 22px * .8;
			font-weight: 700;
			margin-bottom: 20px * .8;
			@include minw( $grid-breakpoints-md ) {
				font-size: 22px;
				margin-bottom: 20px;
			}
		}
		&-button {
			&-group {
				@include minw( $grid-breakpoints-xs ) {
					display: grid;
					min-width: 189px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					display: flex;
					align-items: center;
					flex-direction: row-reverse;
					margin-top: 15px;
					&-cell {
						width: 100%;
						&:last-child {
							margin-right: 12px;
						}
					}
				}
			}
			&-favourite {
				height: 40px;
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 10px;
				}
				&.favourite {
					&:not(.favourite-active) {
						.app-icon svg {
							fill: none;
							stroke: currentColor;
						}
					}
					&-active {
						border: none;
					}
				}
			}
		}
		&-text {
			font-size: 16px * .9;
			font-weight: 400;
			margin-bottom: 12px;
			max-width: 635px;
			@include minw( $grid-breakpoints-md ) {
				font-size: 16px;
			}
			&-container {
				margin-top: 50px * .8;
				@include minw( $grid-breakpoints-md ) {
					margin-top: 50px;
				}
			}
		}
		&-form {
			margin-top: 70px * .8;
			padding: 15px 10px;
			box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
			border-radius: 10px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 35px * .8 30px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-top: 70px;
				padding: 35px 30px;
			}
			.mx-input,
			.a-dropdown__slot {
				min-height: 47px;
			}
			.a-booking-form-search-tourists {
				padding-top: 20px;
			}
		}
		&-comfort {
			max-width: 570px;
			&-list {
				display: flex;
				flex-wrap: wrap;
			}
			&-item {
				display: flex;
				align-items: center;
				font-size: 16px * .9;
				font-weight: 400;
				margin-bottom: 5px;
				flex: 0 0 100%;
				max-width: 100%;
				@include minw($grid-breakpoints-xs) {
					font-size: 16px;
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
				}
				.app-icon {
					color: rgba($cyprus, .5);
					margin-right: 10px;
				}
			}
		}
		&-rooms {
			background-color: rgba($cyprus, .03);
			padding: 20px 10px;
			padding-bottom: 5px * .6;
			margin: 0 -10px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px * .8;
				padding-bottom: 5px * .8;
				margin: 0;
				border-radius: 5px;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 30px;
				padding-bottom: 5px;
			}
			&-box {
				margin-bottom: 25px;
			}
		}
		&-iframe-review {
			width: 100%;
			height: 450px;
			@include minw( $grid-breakpoints-xs ) {
				height: 325px;
			}
		}
		.sticky {
			position: sticky;
			position: -webkit-sticky;
			top: 86px;
		}
	}
	.a-hotel-tripadvisor {
		@include minw( $grid-breakpoints-xs ) {
			justify-content: flex-end;
		}
	}

	.a-hotel-select-row {
		@include maxw( $grid-breakpoints-xs - 1 ) {
			flex-direction: column;
			align-items: baseline;
			.col-auto {
				width: 100%;
				flex: 1 1 100%;
				max-width: 100%;
			}
		}
	}
	.a-hotel-select-sorting {
		@include minw( $grid-breakpoints-xs ) {
			display: flex;
			align-items: center;
			.app-label {
				margin-bottom: 0;
				margin-right: 16px;
			}
			.a-select {
				width: 200px;
				&__dropdown--item {
					span {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
		@include maxw( $grid-breakpoints-xs - 1 ) {
			margin-top: 16px;
		}
	}
</style>
