<template>
	<div ref='a-card-details' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="translate-right">
			<div class='a-card-details' v-if='value'>
				<div class='a-card-details-wrap' v-click-outside="closeDialog">
					<div class='a-card-details-header'>
						<div class="row align-items-center">
							<div class="col">
								<p class='a-card-details-header-title'>{{$t('account.details.hotels.title')}}</p>
							</div>
							<div class="col-auto">
								<div class='a-card-details-header-button' @click='closeDialog'>
									<v-icon icon='cross' size='17' />
								</div>
							</div>
						</div>
					</div>
					<template v-if='errorMessage && errorMessage.length'>
						<div class='a-card-details-content'>
							<trips-preloader
								name='hotels'
								:title='errorMessage'
							/>
						</div>
					</template>
					<template v-else>
						<div class='a-card-details-content' v-if='loading'>
							<div class='a-hotel-card-details-wrap'>
								<div class='a-hotel-card-details-header'>
									<div class="row">
										<div class="col">
											<ul class='a-hotel-stars-list' style='margin-bottom: 10px'>
												<li class='a-hotel-stars-item' style='margin-left: 0'>
													<span class='skeleton' :style='`width: 67px; height: 12px`' />
												</li>
											</ul>
											<p class='a-hotel-card-details-title'><span class='skeleton' :style='`width: 220px; height: 26px`' /></p>
											<div class='row'>
												<div class='col-auto'>
													<p class='a-hotel-card-details-descr'><span class='skeleton' :style='`width: 80px; height: 15px; margin-bottom: 5px`' /></p>
													<p class='a-hotel-card-details-text'><span class='skeleton' :style='`width: 120px; height: 19px`' /></p>
												</div>
												<div class='col-auto'>
													<p class='a-hotel-card-details-descr'><span class='skeleton' :style='`width: 80px; height: 15px; margin-bottom: 5px`' /></p>
													<p class='a-hotel-card-details-text'><span class='skeleton' :style='`width: 120px; height: 19px`' /></p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class='a-hotel-card-details-content'>
									<p class='a-hotel-card-details-descr'><span class='skeleton' :style='`width: 95px; height: 15px`' /></p>
									<div class='a-hotel-card-details-item'>
										<div class="row">
											<div class="col">
												<p class='a-hotel-card-details-item-title'><span class='skeleton' :style='`width: 100px; height: 19px`' /></p>
											</div>
											<div class="col-auto">
												<p class='a-hotel-card-details-text'><span class='skeleton' :style='`width: 60px; height: 19px`' /></p>
											</div>
										</div>
									</div>
								</div>

								<div class='a-hotel-card-details-footer'>
									<div class="row">
										<div class="col">
											<p class='a-hotel-card-details-footer-title'><span class='skeleton' :style='`width: 75px; height: 21px`' /></p>
										</div>
										<div class="col-auto">
											<p class='a-hotel-card-details-footer-title'><span class='skeleton' :style='`width: 75px; height: 21px`' /></p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class='a-card-details-content' v-else>
							<div class='a-hotel-card-details-wrap'>
								<div class='a-hotel-card-details-header'>
									<div class="row">
										<div class="col">
											<ul class='a-hotel-stars-list' style='margin-bottom: 10px'>
												<li class='a-hotel-stars-item' v-for='(item, i) in details.categorySimpleCode' :key='i'>
													<v-icon icon='star' size='14' />
												</li>
											</ul>
											<p class='a-hotel-card-details-title'>{{details.name}}</p>
											<div class='row'>
												<div class='col-auto'>
													<p class='a-hotel-card-details-descr'>{{$t('account.form.placeholder.dateFrom')}}</p>
													<p class='a-hotel-card-details-text' v-if='details.searchData'>{{$moment(details.searchData.dateFrom).format("dddd, MMM DD")}}</p>
												</div>
												<div class='col-auto'>
													<p class='a-hotel-card-details-descr'>{{$t('account.form.placeholder.dateTo')}}</p>
													<p class='a-hotel-card-details-text' v-if='details.searchData'>{{$moment(details.searchData.dateTo).format("dddd, MMM DD")}}</p>
												</div>
											</div>
										</div>
										<!-- <div class="col-auto">
											<div class='a-hotel-card-details-logo'>
												<p>logo</p>
											</div>
										</div> -->
									</div>
								</div>

								<div class='a-hotel-card-details-content'>
									<p class='a-hotel-card-details-descr'>{{$t('account.details.hotels.selected_numbers')}}:</p>
									<div class='a-hotel-card-details-item'>
										<div class="row">
											<div class="col">
												<p class='a-hotel-card-details-item-title'>
													<!-- <span>4х</span> -->
													{{details.roomName}}
												</p>
												<span class='a-hotel-card-details-text'>{{details.boardName}}</span>
											</div>
											<div class="col-auto">
												<p class='a-hotel-card-details-text'>{{$helpers.amount(details.amount, details.currency)}}</p>
											</div>
										</div>
										<!-- <ul class='a-hotel-rooms-item-list'>
											<li class='a-hotel-rooms-item-list-cell'>
												<v-icon icon='wifi' size='13' />
												Безкоштовний Wi-Fi
											</li>
										</ul> -->
									</div>
								</div>

								<div class='a-hotel-card-details-footer'>
									<div class="row">
										<div class="col">
											<p class='a-hotel-card-details-footer-title'>{{$t('account.details.hotels.total')}}</p>
										</div>
										<div class="col-auto">
											<p class='a-hotel-card-details-footer-title'>{{$helpers.amount(details.amount, details.currency)}}</p>
										</div>
									</div>

									<template v-if='details.rateComments'>
										<div class="divider"></div>

										<div class="row">
											<div class="col">
												<p class='a-hotel-card-details-footer-text'>{{$t('account.details.hotels.tariff_details')}}</p>
											</div>
										</div>
										<p class='a-hotel-card-details-footer-descr' v-html='details.rateComments'></p>
									</template>
								</div>

								<div class='a-hotel-card-details-choose' v-if='infoTransfers.length || infoServices.length'>
									<div class='a-hotel-card-details-choose-item' v-if='infoTransfers.length'>
										<p class='a-hotel-card-details-footer-title'>{{$t('account.details.hotels.transfer')}}</p>
										<div class="row a-hotel-card-details-choose-row" v-for='(item, i) in infoTransfers' :key='i'>
											<div class="col">
												<v-radio
													:id="item.id"
													name='id'
													:label="item.name"
													:value='item.id'
													v-model='id'
												/>
											</div>
											<div class="col-auto">
												<p class='a-hotel-card-details-text'>{{$helpers.amount(item.price, item.currency)}}</p>
											</div>
										</div>
									</div>
									<!-- <div class='a-hotel-card-details-choose-item' v-if='infoServices.length'>
										<p class='a-hotel-card-details-footer-title'>{{$t('account.details.hotels.additional_services')}}</p>
									</div> -->
								</div>
							</div>

							<a-offer-special-request @input='($event) => userComment = $event' />
						</div>

						<div class='a-card-details-footer'>
							<div class='a-card-details-footer__divider'>
								<div class='divider' />
							</div>

							<div class="row align-items-center" v-if='loading'>
								<div class="col">
									<span class='skeleton' :style='`width: 90px; height: 24px; margin-bottom: 4px`' />
									<span class='skeleton' :style='`width: 140px; height: 14px`' />
								</div>
								<div class="col-auto">
									<span class='skeleton' :style='`width: 125px; height: 47px`' />
								</div>
							</div>

							<div class="row align-items-center" v-else>
								<div class="col">
									<p class='a-card-details-footer-title'>{{$helpers.amount((findTransfer ? findTransfer.price : 0) + details.amount, details.currency)}}</p>
									<p class='a-card-details-footer-descr' v-if='details.searchData'>{{$t('account.the_price_is_generally_for')}} {{$moment(details.searchData.dateTo).diff($moment(details.searchData.dateFrom), 'days')}} {{$helpers.declOfNum($moment(details.searchData.dateTo).diff($moment(details.searchData.dateFrom), 'days'), $t('account.declOfNum.nights'))}}</p>
								</div>
								<div class="col-auto">
									<v-button xxsmall color='green white--text' class='a-card-details-footer-button'
										@click='addOrder' :loading='loading_btn'
									>{{$t('account.buttons.choose')}}</v-button>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import AOfferSpecialRequest from '@/views/account/offers/components/special-request';
	import TripsPreloader from '@/views/account/search/components/preloader';
	import { hotelDetails, addOrder } from '@/services/request';
	import _ from 'lodash'

	export default {
		data: () => ({
			loading: false,
			loading_btn: false,
			errorMessage: null,
			details: [],
			userComment: '',
			id: null
		}),
		props: ['value', 'transfers', 'offer', 'form'],
		components: {
			AOfferSpecialRequest,
			TripsPreloader
		},
		computed: {
			infoTransfers() {
				return this.details?.additionalServices?.transfers ?? [];
			},
			findTransfer() {
				return this.infoTransfers.find(el => el.id === this.id);
			},
			someTransfer() {
				return this.infoTransfers.some(el => el.necessity === "oneRequired");
			},
			infoServices() {
				return this.details.additionalServices.services;
			}
		},
		methods: {
			hotelDetails() {
				this.id = null
				this.errorMessage = null;
				this.loading = true;

				hotelDetails({
					storageId: this.$route.query.storageId,
					code: String(this.$route.params.code),
					identifier: this.offer.identifier
				}).then(res => {
					this.details = res.data;
				}).catch(err => {
					this.details = [];
					this.errorMessage = err.response.data.message
				}).finally(() => this.loading = false)
			},
			addOrder() {
				if(this.someTransfer) {
					if(this.id) {
						this.$emit("open-dialog", this.findTransfer);
						this.$emit("update-message", this.userComment);
					} else {
						this.$store.commit('notification/errorMessage', {title: 'error'})
					}
				} else {
					if(this.id) {
						this.$emit("open-dialog", this.findTransfer);
						this.$emit("update-message", this.userComment);
					} else {
						this.requestOrder();
					}
				}
			},
			requestOrder() {
				this.loading_btn = true;
				let replaceOfferId = this.form.replaceOfferId ? { replaceOfferId: this.form.replaceOfferId } : false;

				addOrder({
					details: {
						hotelCode: this.$route.params.code,
						userComment: this.userComment
					},
					type: "hotel",
					orderId: this.$route.query.orderId,
					offerId: this.offer.identifier,
					storageId: this.$route.query.storageId,
					...replaceOfferId
				}).then(res => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: res.data.id, lang: this.$route.params.lang},
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			closeDialog() {
				if(this.transfers) return;
				this.$emit('input', false);
			}
		},
		watch: {
			value() {
				if(this.value) {
					this.hotelDetails();
				}
				setTimeout(() => {
					const hideEl = document.querySelector('.a-card-details-content')
					this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150);
			},
			infoTransfers() {
				if(this.infoTransfers.length > 0) {
					this.id = _.minBy(this.infoTransfers, 'price').id
				}
			}
		},
		beforeDestroy() {
			enablePageScroll();
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-hotel-card-details {
		&-wrap {
			@include minw( $grid-breakpoints-xs ) {
				border-radius: 10px;
				box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.2);
				margin-bottom: 35px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-bottom: 35px;
			}
		}
		&-header {
			padding: 20px 0;
			border-bottom: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px * .8 35px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 30px 35px 25px;
			}
		}
		&-content {
			padding: 20px 0;
			@include minw( $grid-breakpoints-xs ) {
				padding: 20px * .8 35px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 35px 25px;
			}
		}
		&-title {
			font-size: 22px * .8;
			font-weight: 700;
			margin-bottom: 25px * .8;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 22px;
			}
			@include minw( $grid-breakpoints-md ) {
				margin-bottom: 25px;
			}
		}
		&-descr {
			font-size: 13px;
			font-weight: 400;
			color: rgba($black, .6);
		}
		&-text {
			font-size: 16px * .9;
			font-weight: 400;
			text-transform: capitalize;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
			}
		}
		&-item {
			margin-top: 10px * .8;
			@include minw( $grid-breakpoints-md ) {
				margin-top: 10px;
			}
			&:not(:last-child) {
				margin-bottom: 25px * .8;
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 25px;
				}
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 10px * .8;
				@include minw( $grid-breakpoints-md ) {
					margin-bottom: 10px;
				}
			}
		}
		&-footer {
			padding: 15px 0;
			border-top: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 15px * .8 35px * .8 30px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 15px 35px 30px;
			}
			.divider {
				margin-bottom: 20px;
			}
			&-title {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 15px;
			}
			&-text {
				font-size: 13px;
				font-weight: 400;
				line-height: 1.5;
				color: $orange;
				margin-bottom: 5px;
			}
			&-descr {
				font-size: 12px;
				font-weight: 400;
				line-height: 1.5;
				color: rgba($black, .6);
			}
		}
		&-choose {
			padding: 20px 0;
			border-top: 1px solid rgba($black, .1);
			@include minw( $grid-breakpoints-xs ) {
				padding: 30px * .8 35px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 30px 35px;
			}
			&-item {
				max-width: 430px;
				&:not(:last-child) {
					margin-bottom: 35px;
				}
			}
			&-row {
				margin-bottom: 15px;
			}
		}
	}
</style>
